import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { Loading } from '@stb-component-svg';
import { logout as logoutSvg } from '@stb-svg';
import { logOut } from '@stb-module-auth/actions';
import { useWindowSize } from '@stb-utils/hooks';
import {
  LIST_MENU_DEFAULT,
  LIST_MENU_FACTORY,
  LIST_MENU_FOR_GENTARI,
  LIST_MENU_FOR_PLN,
  LIST_MENU_FOR_STB_PARTNER,
} from './data';
import { CLEAR_PARAMETER } from '@stb-redux/extra-constants/system-parameter';
import { setOpenMenu } from '@stb-component-layout/Header/headerActions';
import './styles.scss';
import SidebarMenu from './menu';

const HIDDEN_POSITION = -300;

function Sidebar({ menuIsOpen, setOpenMenu }) {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const [loadingLogout, setLoadingLogout] = useState(false);
  const location = useLocation();

  function doLogOut() {
    if (loadingLogout) {
      return;
    }
    swal({
      title: 'Apa anda yakin ingin logout?',
      text: 'Anda akan diarahkan ke halaman login',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Batalkan',
          value: false,
          visible: true,
          className: 'btn-secondary',
          closeModal: true,
        },
        confirm: {
          text: 'Ya',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true,
        },
      },
    }).then((yes) => {
      if (yes) {
        setLoadingLogout(true);
        setTimeout(() => {
          setLoadingLogout(false);
          dispatch(logOut());
          dispatch({
            type: CLEAR_PARAMETER,
          });
        }, 1000);
      }
    });
  }

  const sidebarPositionStyle =
    windowSize > 1024
      ? {
          left: menuIsOpen ? 0 : HIDDEN_POSITION,
        }
      : {
          right: menuIsOpen ? 0 : HIDDEN_POSITION,
        };

  const user = useSelector((state) => state.auth.session.user);
  const role_user = user.role_user_id;

  let listSidebar = [];

  switch (role_user) {
    case parseInt(process.env.REACT_APP_ROLE_FACTORY):
      listSidebar = LIST_MENU_FACTORY;
      break;
    case parseInt(process.env.REACT_APP_ROLE_PERTAMINA):
      listSidebar = LIST_MENU_FOR_STB_PARTNER;
      break;
    case parseInt(process.env.REACT_APP_ROLE_PLN):
      listSidebar = LIST_MENU_FOR_PLN;
      break;
    case parseInt(process.env.REACT_APP_ROLE_ARKADIA):
      listSidebar = LIST_MENU_FOR_STB_PARTNER;
      break;
    case parseInt(process.env.REACT_APP_ROLE_GENTARI):
      listSidebar = LIST_MENU_FOR_GENTARI;
      break;
    default:
      listSidebar = LIST_MENU_DEFAULT;
  }

  return (
    <>
      <Col style={sidebarPositionStyle} className="sidebar">
        <div className="menu">
          {listSidebar.map((item, index) => {
            let isOpenCollapse = false;
            for (let i in item.children) {
              if (item.children[i].to === location.pathname) {
                isOpenCollapse = true;
                break;
              }
            }

            return (
              <SidebarMenu
                key={index}
                item={item}
                openMenuHandler={() => {
                  if (windowSize <= 1024) setOpenMenu();
                }}
                isOpenCollapse={isOpenCollapse}
              />
            );
          })}
        </div>
        <a
          onClick={doLogOut}
          className="btn btn-secondary btn-submit d-flex flex-row align-items-center my-4 mx-2 justify-content-center"
        >
          {!loadingLogout ? (
            <>
              <img src={logoutSvg} className="me-4" />
              <p className="m-0">Log out</p>
            </>
          ) : (
            <Loading height={100} />
          )}
        </a>
      </Col>
    </>
  );
}

const mapStateToProps = (state) => ({
  menuIsOpen: state.header.menuIsOpen,
});
const mapDispatchToProps = {
  setOpenMenu: (payload) => setOpenMenu(payload),
};

Sidebar.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
